*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.chimp-forms button {
    width: 100px;
    height: 40px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    margin-left: 5px;
}

.chimp-forms button::before {
    content: "";
    background: linear-gradient(45deg,
            #f43e07,
            #dc3806,
            #c33206,
            #ab2b05,
            #922504,
            #7a1f04,
            #621903,
            #491302,
            #180601);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.chimp-forms button:active {
    color: #000;
}

.chimp-forms button:active::after {
    background: transparent;
}

.chimp-forms button:hover::before {
    opacity: 1;
}

.chimp-forms button::after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.chimp-forms input {
  width: 180px;
  height: 30px;
  border: none;
  outline: none;
  color: black;
  background: #f0e8c2;
  cursor: pointer;
  font-family: 'Bona Nova', serif;
  text-align: center;
  margin-bottom: 5px;
}
