.game {
  padding: 20px;
}

.game-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.upcoming {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upcoming-intro {
  display: flex;
}

.upcoming-intro-spring {
  margin-bottom: 20px;
}

.game_kickstarter {
  height: 25px;
  width: 170px;
  cursor: pointer;
  margin-left: 5px;
}

.intro-img {
  width: 100%;
  margin-bottom: 30px;
  box-shadow: -11px 10px 38px 0px rgba(0,0,0,0.64);
  -webkit-box-shadow: -11px 10px 38px 0px rgba(0,0,0,0.64);
  -moz-box-shadow: -11px 10px 38px 0px rgba(0,0,0,0.64);
}

.how-to-heading {
  font-family: 'Bona Nova', serif;
    font-style: italic;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 2px;
}

.card {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  border-radius: 8px;
  padding: 10px;
  box-shadow: -11px 10px 38px 0px rgba(0,0,0,0.64);
  -webkit-box-shadow: -11px 10px 38px 0px rgba(0,0,0,0.64);
  -moz-box-shadow: -11px 10px 38px 0px rgba(0,0,0,0.64);
}

.card-img {
  width: 100%;
}

.card-text {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'Bona Nova', serif;
}

.card-img {
  width: 100%;
}

.card-gif {
  width: 100%;
}

.game-belt {
  height: 23px;
  width: 278px;
  margin-bottom: 30px;
}

/*Tablet*/
@media (min-width: 43rem) {
  .game {
    padding: 40px 80px 80px;
  }

  .upcoming {
    font-size: 22px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .game_kickstarter {
    margin-left: 5px;
  }

  .upcoming-intro-spring {
    margin-bottom: 40px;
  }

  .how-to-heading { 
    font-size: 32px;
  }

  .card {
    padding: 20px;
  }

  .card-text {
    line-height: 31px;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .game-belt {
    height: 29px;
    width: 372px;
    margin-bottom: 30px;
  }
}

/*Desktop*/
@media (min-width: 62rem) { 
  .intro-img {
    min-width: 300px;
    max-width: 800px;
  }

  .cards-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 45%;
    margin: 0 10px 60px;
  }

  .last-card {
    display: flex;
    justify-content: center;
  }
}