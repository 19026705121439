.about {
  width: 100%;
  color: #fff;
  font-size: 1em;
  padding: 20px;
}

.tex-img-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-section {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 25px;
  line-height: 19px;
}

.heading {
  font-size: 16px;
  margin-bottom: 5px;
}

.text-content {
  text-align: left;
  max-width: 300px;
}

.about-img {
  height: 300px;
  width: 300px;
  margin-bottom: 50px;
  box-shadow: -11px 10px 38px 0px rgba(0,0,0,0.64);
  -webkit-box-shadow: -11px 10px 38px 0px rgba(0,0,0,0.64);
  -moz-box-shadow: -11px 10px 38px 0px rgba(0,0,0,0.64);
}

/*Tablet*/
@media (min-width: 43rem){
  .about {
    padding: 35px 15px;
  }

  .tex-img-section {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
  }

  .text-section{
    display: flex;
    flex-direction: column;
    line-height: 22px;
  }

  .heading {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .about-img {
    height: 400px;
    width: 400px;
    margin: 30px;
  }
}

/*Desktop*/
@media (min-width: 62rem){
  .about {
    padding: 35px 15px;
  }

  .tex-img-section {
    margin-bottom: 35px;
  }

  .text-section{
    display: flex;
    flex-direction: column;
    padding: 30px;
    line-height: 22px;
  }

  .heading {
    font-size: 20px;
    margin-bottom: 7px;
  }

  .about-img {
    margin: 30px;
  }
}