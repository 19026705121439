.navbar {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content:center;
  font-size: 19px;
  border-bottom: #fff solid 2px;
}

.navbar-logo-link {
  cursor: pointer; 
}

.navbar-logo {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 0;
  left: 20px;
  transform: translate(25%, 50%);
}

.nav-menu {
  display: none;
}

.menu-icon {
  display: block;
  position: absolute;
  top: 10px;
  right: 0;
  transform: translate(-100%, 60%);
  cursor: pointer;
  height: 30px;
  width: 30px;
  filter: invert(1);
}

.nav-menu.active {
  display: flex;
  opacity: 1;
  flex-direction: column;
  position: absolute;
  width: 50%;
  height: 100%;
  top: 60px;
  right: 0;
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links {
  text-align: center;
  padding: 2rem;
  width: 100%;
  display: table;
}

.nav-links:hover {
  background-color: #f43e07;
  opacity: 70%;
 }

@media screen and (min-width: 62rem) {
  .nav-menu {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-right: 50px;
  }

  .navbar-logo {
    height: 50px;
    width: 50px;
    top: -11px;
    left: 30px;
  }

  .menu-icon {
    display: none;
  }

  .nav-menu.active {
    display: none;
  } 
}
