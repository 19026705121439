.home {
  color: #fff;
  font-size: 1em;
  padding: 20px;
}

.hero {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.fyri-logo {
  height: 225px;
  width: 431px;
}

.game-img {
  height: 200px;
  width: 350px;
  margin-top: -16px;
}

.text-section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

.emphasized-text {
  font-style: italic;
}

.home-text-section {
  line-height: 25px;
  font-family: 'Bona Nova', serif;
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  border-radius: 8px;
  padding: 10px;
  margin-top: 15px;
}

.welcome-text {
  font-family: 'Bona Nova', serif;
  font-style: italic;
  font-size: 18px;
  font-weight: 500;
  margin-top: 30px;
}

.red-star {
  color: #f43e07;
  font-size: 30px;
  font-weight: 700;
  vertical-align: -webkit-baseline-middle;
}

.belt {
  height: 15px;
  width:200px;
  margin-bottom: 50px;
}

.sign-up {
  margin-bottom: 10px;
}

.kickstarter {
  height: 25px;
  width: 170px;
  margin-bottom: 20px;
  cursor: pointer; 
}

.or {
  margin: 10px;
}

.form {
  text-align: left;
  font-size: 10px;
}

.notis {
  margin-top: 15px;
  margin-bottom: 50px;
}

/*Tablet*/
@media (min-width: 43rem) {
  .home {
    padding: 40px;
  }

  .home-text-section {
    width: 500px;
    line-height: 27px;
    font-size: 18px;
  }

  .fyri-logo {
    height: 250px;
    width: 480px;
    margin-top: 40px;
  }

  .game-img {
    height: 275px;
    width: 480px;
    margin-bottom: 20px;
  }

  .welcome-text {
    font-size: 20px;
    margin-top: 50px;
  }

  .belt {
    height: 23px;
    width:223px;
    margin-bottom: 50px;
  }

  .sign-up {
    font-size: 18px;
  }

  .or {
    font-size: 18px;
  }

  .notis {
    font-size: 18px;
  }
}

/*Desktop*/
@media (min-width: 62rem) {
  .fyri-logo {
    height: 332px;
    width: 630px;
  }

  .game-img {
    height: 313px;
    width: 550px;
  }

  .home-text-section {
    width: 600px;
    line-height: 30px;
    font-size: 20px;
  }

  .welcome-text {
    font-size: 25px;
    margin-top: 70px;
  }

  .belt {
    height: 28px;
    width: 280px;
    margin-bottom: 70px;
  }

  .kickstarter {
    height: 37px;
    width: 228px;
    margin-bottom: 30px;
  }

  .sign-up {
    font-size: 18px;
  }

  .or {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .notis {
    font-size: 18px;
    margin-bottom: 50px;
  } 
}
