.contact {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-details {
  text-align: left;
  margin-top: 50px;
  line-height: 25px;
}

.email {
  font-weight: 700;
}

.socials-icons {
  margin-top: 40px;
  margin-bottom: 40vh;
  cursor: pointer; 
}

.icon {
  height: 40px;
  width: 40px;
  margin: 2px;
}

/*Tablet up*/
@media (min-width: 43rem) {
  .icon {
    height: 60px;
    width: 60px;
    margin: 4px;
  }

  .contact-details {
    margin-top: 100px;
    line-height: 30px;
    font-size: 22px;
  }
}

/*Desktop*/
@media (min-width: 62rem){
  .icon:hover {
    filter: invert();
  }
}