.footer {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.socialmedia-icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer; 
  vertical-align: middle;
}

.footer-icons {
  height: 15px;
  width: 15px;
  margin: 2px;
}

.info {
  color: #fff;
  font-size: 10px;
  line-height: 12px;
  font-family: adobe-jenson-pro, serif;
  font-style: normal;
  font-weight: 300;
  margin-top: 2px;
}

/*Tablet*/
@media (min-width: 43rem){
  .footer-icons {
    height: 20px;
    width: 20px;
  }
}

/*Desktop*/
@media (min-width: 62rem){
  .info {
    font-size: 14px;
    line-height: 16px;
  }

  .footer-icons {
    height: 25px;
    width: 25px;
  }

  .footer-icons:hover {
    filter: invert();
  }
}
